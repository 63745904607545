@use '../../../variables' as var;
.RewardsPlan {
  width: 100%;
  background: var.$color-card-background;
  border-radius: var.$border-radius;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}
.title {
  text-align: left;
  font-size: 24px;
}
.planWrap {
  width: 100%;
  display: flex;
  gap: 15px;
}
.planCard {
  flex: 1 1 0;
  width: 100%;
  background: white;
  border-radius: var.$border-radius;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  overflow: hidden;
  &:first-child {
    justify-content: space-around;
  }
}
.infoWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  h2 {
    font-size: 16px;
  }
  p {
    font-size: 52px;
    font-weight: 700;
    color: black;
  }
}
.actionWrap {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 15px;
}
.linkButton {
  width: 150px;
}
.infoItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dataWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  text-align: left;
  .dataTitle {
    font-size: 16px;
    line-height: 16px;
    img {
      width: 15px;
    }
  }
  h3 {
    margin-top: 5px;
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
  }
  h4 {
    margin: 0;
    font-size: 14px;
    color: #1d1b27;
    opacity: 0.5;
  }
}

// :global(.ant-modal-content) {
//   background: #27224f;
// }
.claimModalContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.claimModalInfoItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 15px;
  }
  h2 {
    font-size: 16px;
  }
  p {
    font-size: 16px;
    font-weight: 700;
    span {
      opacity: 0.5;
    }
  }
}
.desc {
  font-size: 16px;
  line-height: 120%;
  color: black;
  opacity: 0.5;
  margin: 20px 0;
}

@media (max-width: var.$mobile-break-point) {
  .planWrap {
    flex-direction: column;
  }
  .planCard {
    width: 100%;
  }
}
