@use '../../variables' as var;

.Profile {
  color: #ffffff;
  width: 100%;
  position: relative;
  overflow: hidden;
  // background: #1c152e;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #ededed;
  @media (max-width: var.$mobile-break-point) {
    margin-top: -50px;
  }
}

.banner {
  height: 80vh;
  width: 100%;
  background-image: url(./Banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0 1% 2rem 1%;
  margin-bottom: 2rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  & > div {
    width: 100%;
    max-width: 1000px;
  }
}

.bannerInfo {
  position: relative;
  background: white;
  // border: 2px solid #281d40;
  border-radius: var.$border-radius;
  height: 100px;
  text-align: start;
  white-space: nowrap;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h2 {
    font-weight: 700;
    font-size: 18px;
    color: black;
  }

  p {
    font-weight: 600;
    font-size: 16px;
    color: var.$color-primary;
  }
  .small {
    font-size: 14px;
  }
  // &::before {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   content: ' ';
  //   height: 100%;
  //   width: 10px;
  //   background: #9644b4;
  //   border-radius: var.$border-radius;
  // }
  @media (max-width: var.$mobile-break-point) {
    height: 100px;

    h2 {
      font-size: 16px;
    }

    p {
      font-weight: 600;
      font-size: 14px;
    }
    .small {
      font-size: 12px;
    }
    &::before {
      width: 5px;
    }
  }
}

.mobileJustifyCenter {
  @media (max-width: var.$mobile-break-point) {
    justify-content: center;
  }
}

.secondaryTitle {
  font-weight: 700;
  font-size: 32px;
  text-align: left;
  width: fit-content;
  @media (max-width: var.$mobile-break-point) {
    font-size: 24px;
  }

  background: var.$color-title-gradient;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
}

.subtitle {
  font-size: 14;
  color: #e7e7e7;
  opacity: 0.5;
  margin-top: -2.5rem;
}

.contentContainer {
  width: 90%;
  max-width: 1000px;
}

.tabsContainer {
  background: white;
  border-radius: var.$border-radius;
  padding: 16px;
  margin-bottom: 2rem;
}

.nftList {
  max-height: 80vh;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.tabSelector {
  position: relative;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0;
  color: black;
  cursor: pointer;
  @media (max-width: var.$mobile-break-point) {
    font-size: 16px;
  }
}

.tabSelected {
  // background: var.$color-title-gradient;
  // -webkit-background-clip: text;
  // background-clip: text;
  // -webkit-text-fill-color: transparent;
  color: var.$color-primary;
}

.tabSelector::after {
  position: absolute;
  bottom: 0;
  left: 2.5%;
  content: '';
  width: 95%;
  height: 4px;
  border-radius: 2px;
  background: black;
}

.tabSelected::after {
  background: var.$color-primary;
}

.nodeContainer {
  text-align: start;
  position: relative;
  width: 100%;
  cursor: pointer;
  img {
    width: 100%;
  }

  h3 {
    padding: 5px 0;
    font-weight: 600;
    font-size: 13px;
    background: var.$color-title-gradient;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.nodeInfosListContainer {
  margin-left: -8px;
  margin-right: -8px;

  & > div {
    padding: 8px;
  }
}

.nodeInfoContainer,
.nodeTotalInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.nodeInfoContainer {
  background: linear-gradient(102.16deg, #332d4f 0%, #231f4f 100%);
  border-radius: var.$border-radius;
  padding: 1rem;
  gap: 10px;
  h3 {
    font-weight: 700;
    font-size: 20px;
    background: var.$color-title-gradient;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0;
  }

  p:nth-child(2) {
    font-weight: 700;
    font-size: 22px;
  }

  p:nth-child(4) {
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    flex: 1;
  }

  & > div:nth-child(3) {
  }

}
.nodeInfoContainerButton {
  width: 100%;
  height: 45px;
}

.nodeTotalInfoContainer {
  justify-content: center;
  h3 {
    padding: 1rem;
    font-weight: 700;
    font-size: 24px;
    background: var.$color-title-gradient;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0;
  }

  p {
    font-weight: 700;
    font-size: 24px;
  }
}

.referralContainer {
  background: white;
  border-radius: var.$border-radius;
  text-align: start;
  padding: 15px;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  h3 {
    font-weight: 700;
    font-size: 16px;
    background: var.$color-button-gradient;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0;
  }

  p {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 0.5rem;
  }

  input {
    border: none;
    background: #ededed;
    border-radius: var.$border-radius;
    outline: none;
    padding: 8px 16px;
    color: #ffffff;
    opacity: 1;
    width: 100%;
    font-weight: 700;
    text-overflow: ellipsis;
  }

  .referralDetailButton {
    font-size: 20px;
    cursor: pointer;
  }

  button {
    border: none;
    background: none;
    outline: none;
    font-weight: 500;
    font-size: 24px;
    background: var.$color-title-gradient;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-top: 1.5rem;
  }
  .referralLinkWrap {
    width: 100%;
    & > div:nth-child(2) {
      padding-left: 1rem;
      @media (max-width: var.$mobile-break-point) {
        padding-left: 0;
        padding-top: 0.5rem;
      }
    }
  }
}

.stakeContainer {
  position: relative;
  width: 100%;

  & > div {
    width: 50%;
    background: #060609;
    padding: 2rem;
    border-radius: var.$border-radius;
    flex-direction: column;

    @media (max-width: var.$mobile-break-point) {
      width: 90%;
    }

    h3 {
      font-weight: 600;
      font-size: 24px;
      background: var.$color-button-gradient;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .stakeInput {
      position: relative;
      margin-bottom: 0.5rem;

      input {
        border: none;
        background: #0e0c16;
        border-radius: var.$border-radius;
        outline: none;
        padding: 1rem 2rem;
        color: #ffcd1d;
        width: 100%;
        font-size: 24px;
        font-weight: 700;
        text-align: center;
      }

      button {
        display: flex;
        align-items: center;
        height: 100%;
        position: absolute;
        top: 0;
        border: none;
        background: #1e1a30;
        outline: none;
        font-weight: 500;
        font-size: 48px;
        padding: 0 1rem;
      }

      button:nth-child(2) {
        border-radius: var.$border-radius 0 0 var.$border-radius;
        left: 0;
      }

      button:nth-child(3) {
        border-radius: 0 var.$border-radius var.$border-radius 0;
        right: 0;
      }
    }

    & > div {
      width: 100%;
    }

    & > p {
      text-align: start;
      width: 100%;
      color: #ffffff;
      margin-bottom: 1rem;

      span:nth-child(1) {
        opacity: 0.5;
      }
    }
  }
}
.tagWrap {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  .phaseTag,
  .stakeTag {
    font-size: 14px;
    color: var.$color-primary;
  }
  .stakeTag {
    color: #ffcd1d;
    font-weight: 700;
  }
}
.NftDetail {
  width: 100%;
  display: flex;
  flex-direction: column;
  .tagWrap {
    padding: 20px;
  }
  img {
    width: 100%;
  }
  .contentWrap {
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .nftId {
    font-size: 16px;
    font-weight: 700;
  }
  .title {
    font-size: 32px;
    margin-bottom: 0;
  }
  .infoItemGroup {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .infoItem {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      color: var.$color-primary;
      font-size: 14px;
      font-weight: 500;
    }
    h2 {
      font-size: 14px;
      font-weight: 700;
      line-height: 100%;
      // color: #e7e7e7;
      opacity: 0.5;
    }
    :global {
      .ant-statistic-content-value {
        color: white;
        font-size: 14px;
      }
    }
    &.unlockable {
      p {
        background: var.$color-title-gradient;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}

.referralDetail {
  overflow: hidden;
  transition: all 0.3s linear;
  height: 0;
  font-size: 14px;
  padding-left: 1.5rem;
  color: black;
  &.show {
    height: auto;
  }
}
.copyButton {
  height: 40px;
}

.ListPlaceholder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 0;
  gap: 30px;
  img {
    width: 100px;
  }
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var.$color-red;
}
