@use '../../../variables' as var;
$gap: 15px;

.header {
  background-color: #010101;
  .headerContainer {
    padding: 25px 0;
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    gap: 15px;
  }
  .headerTitle {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    h2,
    p {
      color: white;
    }
    h2 {
      font-weight: 900;
      font-size: 28px;
    }
  }
}

.TokenPoolAction {
  position: relative;
  min-height: 71vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.poolCard {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.cardHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  .tokenLogo {
    height: 35px;
  }
  .title {
    font-weight: 900;
    text-align: right;
    h2 {
      font-weight: 900;
      font-size: 22px;
    }
  }
}
hr {
  margin: 0;
  border: none;
  border-top: 1px solid #dedede;
}
.infoWrap,
.actionWrap {
  width: 100%;
  display: flex;
  gap: 10px;
}
.infoWrap {
  flex-direction: column;
}
.actionWrap {
}
.button {
  flex: 1 1 0;
  &.remove {
    border-color: var.$color-red;
    color: var.$color-red;
  }
}

.stakeModalContent {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .stakeModalTitle,
  .stakeModalInputWrap {
    margin-bottom: $gap;
  }
  .stakeModalTitle {
    font-weight: 600;
    font-size: 20px;
  }
  .stakeModalBalanceWrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: rgba(black, 0.5);
    font-size: 14px;
    margin-bottom: 5px;
  }
  .stakeModalInputWrap {
    position: relative;
    width: 100%;
    .selectMax {
      font-weight: 900;
      font-size: 14px;
      line-height: 14px;
      color: var.$color-primary;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(calc(-50% - 2px));
    }
  }
  .stakeModalButton {
    width: 100%;
    margin-bottom: $gap;
    &.withdraw {
      background-color: var.$color-red;
    }
  }
}

.addLiquidity {
  font-size: 16px;
  color: var.$color-primary;
  img {
    margin-right: 5px;
    width: 18px;
  }
}
