@use '../../variables' as var;

.Points {
  width: 100%;
  position: relative;
  overflow: hidden;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner {
  width: 100%;
  background: url(./Banner.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
}

.bannerTextContainer {
  padding-top: 70px;
  padding-bottom: 0;
}

.bannerText {
  margin-bottom: 30px;
}

.bannerContainer {
  padding-bottom: 0;
  width: 95%;
}

.rankContainer {
  background: transparent;
  border-radius: var.$border-radius;
  padding: 15px;
  max-width: 1000px;
  margin: 0 auto;
}

.topRanksContainerSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;

  & > div {
    height: 100%;
    flex-direction: column;
  }

  & > div > div {
    width: 100%;
  }

  & > div > div > div:nth-child(2) {
    flex-grow: 1;
  }

  img {
    width: 45px;
    padding-top: 0rem;
    padding-bottom: 1rem;
    padding-right: 0.5rem;
  }

  p:nth-child(1) {
    font-size: 14px;
    font-weight: 700;
  }

  p:nth-child(2) {
    font-size: 20px;
    font-weight: 700;
    color: #f5cf41;
  }
}
.rankListTopThree {
  position: relative;
  width: 100%;
  background: url(./RankBackground.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5%;
  .rankRow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 5%;
    &.first {
      .rankItem {
        width: 50%;
      }
    }
    &.second {
      transform: translateY(-20%);
      .rankItem {
        width: 100%;
        flex: 1;
      }
    }
  }
  .rankItem {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    overflow: hidden;
  }
  .rankOrdinal {
    display: none;
  }
  .rankItemBg {
    width: 100%;
  }
  .rankItemText {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    p:nth-child(1) {
      font-size: 14px;
      font-weight: 700;
      color: rgba(black, 0.5);
    }

    p:nth-child(2) {
      font-size: 20px;
      font-weight: 700;
      color: black;
    }
  }
  @media (max-width: var.$mobile-break-point) {
    gap: 15px;
    .rankRow {
      flex-direction: column;
      gap: 15px;
      &.first {
        .rankItem {
          width: 100%;
        }
      }
      &.second {
        transform: none;
        .rankItem {
          flex: none;
        }
      }
    }
    .rankOrdinal {
      display: unset;
      width: 70px;
    }
    .rankItemBg {
      display: none;
    }
    .rankItemText {
      position: relative;
      top: unset;
      left: unset;
      transform: unset;
      p:nth-child(1) {
        font-size: 16px;
      }

      p:nth-child(2) {
        font-size: 24px;
      }
    }
  }
}

.topRanksContainerBig {
  background: url(./RankBackground.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 50vh;
  position: relative;

  & > div:nth-child(1) {
    top: 2%;
    left: 50%;
    transform: translateX(-50%);
    background-image: url(./FirstBackground.png);
    width: 50%;
    height: 60%;
  }

  & > div:nth-child(2) {
    top: 48%;
    left: 50%;
    transform: translateX(-105%);
    background-image: url(./SecondBackground.png);
    width: 38%;
    height: 60%;
  }

  & > div:nth-child(3) {
    top: 48%;
    left: 50%;
    transform: translateX(5%);
    background-image: url(./ThirdBackground.png);
    width: 38%;
    height: 60%;
  }

  & > div {
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    & > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);

      p:nth-child(1) {
        font-size: 16px;
        font-weight: 700;
      }

      p:nth-child(2) {
        font-size: 24px;
        font-weight: 700;
        color: #f5cf41;
      }
    }
  }
}

.mobileJustifyCenter {
  @media (max-width: var.$mobile-break-point) {
    justify-content: center;
  }
}

.secondaryTitle {
  font-weight: 700;
  font-size: 25px;
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
  @media (max-width: var.$mobile-break-point) {
    font-size: 20px;
    margin-bottom: 10px;
  }

  color: black;
}

.rankList {
  border: 1px #2d2d2d solid;
  border-radius: var.$border-radius;
  width: 100%;
  overflow: hidden;

  & > div {
    padding: 0 2rem;
  }

  & > div:nth-child(1) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .rankListHeader {
    font-weight: 700;
    font-size: 16px;
    opacity: 0.5;
    text-align: start;
  }

  .rankListHeader:nth-child(3) {
    text-align: end;
  }

  .rankListIndex,
  .rankListAddress,
  .rankListPoints {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-weight: 700;
    font-size: 14px;
  }

  .rankListIndex,
  .rankListAddress {
    text-align: start;
  }

  .rankListPoints {
    text-align: end;
  }

  .rankListMe {
    background: #7a8290;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    .rankListIndex,
    .rankListPoints {
      color: white;
    }

    .rankListAddress {
      font-weight: 400;
    }

    .rankListMyAddress {
      font-weight: 700;
      color: var.$color-primary;
      margin-right: 5px;
    }
  }

  & > div:last-child {
    margin-bottom: 0;
  }
}
.myAddress {
  color: var.$color-primary;
}

.rankListTip {
  font-size: 14px;
  text-align: center;
  margin-top: 1rem;
}

.contentContainer {
  margin-top: 20px;
  width: 95%;
  max-width: 1000px;
}

.totalEarnedPoints {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;

  div:nth-child(1) {
    font-size: 25px;
  }
}

.earnedSpace {
  padding: 0.5rem;
}

.earnedContainer {
  background-color: var.$color-card-background;
  border-radius: var.$border-radius;
  padding: 15px;
  text-align: start;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
  gap: 5px;
  .pointsButton {
    width: 100%;
  }
  h3 {
    color: inherit;
    font-size: 20px;
    font-weight: 700;
    white-space: nowrap;
  }

  p {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  & > div {
    width: 100%;
    border-radius: 2em;
  }

  ol {
    font-size: 12px;
    padding: 0;
    padding-left: 1em;
    margin: 0;
    opacity: 0.5;
  }
  .earnedDesc {
    font-size: 14px;
    padding: 0;
    margin: 0;
    opacity: 0.5;
  }
  @media (max-width: var.$mobile-break-point) {
    text-align: center;
  }
}

.currentBenifitBackground {
  position: relative;
  margin: 50px 0;

  img {
    position: relative;
    z-index: 1;
    width: 95%;
  }
}

.currentBenifitBackgroundImageSmall {
  margin-top: -10rem;
  margin-bottom: 2rem;
}

.currentBenifitContainer,
.currentBenifitContainerSmall {
  width: 100%;
  background: var.$color-card-background;
  border-radius: var.$border-radius;
  font-size: 14px;
  padding: 2rem 15px;

  table {
    width: 100%;
    margin-top: 1rem;
  }

  table,
  td {
    border: 1px solid #3c2f59;
  }

  td {
    font-size: 14px;
    padding: 0.5em;
  }
}

.currentBenifitContainer {
  position: absolute !important;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  text-align: start;
}

.currentBenifitContainerSmall {
  margin-top: 5rem;
  text-align: justify;
}

.earnedWrap {
  margin-bottom: 70px;
}

@media (max-width: var.$mobile-break-point) {
  .currentBenifitBackground {
    margin-top: 60px;
  }
}
