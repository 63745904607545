$color-button-gradient: black;
$color-title-gradient: black;
$mobile-break-point: 768px;
$header-v-padding: 15px;
$header-h-padding: 25px;
$border-radius: 12px;

$color-primary: #59d89d;
$color-primary-background: #f8f8f8;
$color-header-background: #212222;
$color-card-background: #ededed;
$color-card-background-dark: #7a8290;
$color-red: #f23e74;
