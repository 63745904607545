@use '../../variables' as var;

@mixin flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Coming {
  @include flex;

  max-width: 1400px;
  height: 670px;
  margin: 50px auto;
  position: relative;
  border-radius: var.$border-radius;
  overflow: hidden;

  justify-content: center;

  img {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    height: 100%;
  }
}

.CountDown {
  @include flex;

  justify-content: center;
  width: 351px;
  height: 70px;
  background: linear-gradient(102.16deg, #332d4f 0%, #231f4f 100%);
  border-radius: var.$border-radius;
  color: #fff;

  span {
    margin-right: 10px;
    font-weight: 500;
    font-size: 20px;
  }

  :global {
    .ant-statistic-content-value {
      color: rgba(255, 205, 29, 1);
      font-size: 32px;
      font-weight: 700;
    }
  }
}

.ComingWarp {
  position: relative;
  z-index: 1;
}
.learnMoreLink {
  color: white;
  text-decoration: none;
  font-size: 24px;
  font-weight: 700;
}
.guideLink {
  color: var.$color-primary;
  text-decoration: underline;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
}

.Participate {
  // padding: 0 15px 1px;
  min-height: 70vh;
}

.ParticipateDesc {
  max-width: 1000px;
}

.ParticipateCard {
  background: white;
  border-radius: var.$border-radius;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
  width: 100%;
  position: relative;
}

.ParticipateBuy {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  max-width: 1000px;
  min-height: 557px;
  margin: 0 auto;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 50px;

  img {
    width: 480px;
    height: auto;
  }
}

.ParticipateBuyWarp {
  display: flex;
  justify-content: center;
  // align-items: flex-start;
  width: 100%;
  gap: 45px;
  padding: 25px;
}

.ParticipateBuyImageWarp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  gap: 15px;
  video {
    width: 100%;
    border-radius: var.$border-radius;
  }
}

.BuyButton {
  width: 100%;
  height: 55px;
  .buyButtonContent {
    line-height: 18px;
    span {
      font-size: 12px;
      color: black;
      opacity: 0.5;
    }
  }
}

.ParticipateBuyForm {
  max-width: 500px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}
.ParticipateBuyFormTitle {
  gap: 15px;
  align-items: flex-start;
  h3 {
    font-weight: 900;
    font-size: 28px;
  }
  .ParticipateBuyFormDesc {
    font-weight: 500;
    font-size: 16px;
    text-align: left;
  }
  .notes {
    .ParticipateBuyFormDesc {
      color: var.$color-primary;
      // font-weight: 600;
    }
  }
}

.PaddingWarp {
  display: grid;
  gap: 10px;
  padding: 22px 0px 0px 50px;
}

.Stage,
.Price,
.Cost {
  @include flex;
}

.Price {
  :global {
    .ant-select {
      color: white;
      font-weight: 700;
      font-size: 16px;
    }
    .ant-select-arrow {
      color: white;
    }
    .ant-select-selection-item {
      font-weight: 700;
      display: flex;
      align-items: center;
      img {
        width: 20px;
        margin-right: 8px;
      }
    }
  }
}
:global {
  .currencyDropdown {
    background: #1e1930 !important;
    border-radius: var.$border-radius !important;
    padding: 5px !important;

    .ant-select-item {
      color: white !important;
      background: transparent !important;
      border-radius: var.$border-radius !important;
    }
    .ant-select-item-option-active,
    .ant-select-item-option-selected {
      background: #171328 !important;
      color: white !important;
    }

    .ant-select-item-option-content {
      font-weight: 700 !important;
      font-size: 16px !important;
      display: flex;
      align-items: center;
      img {
        width: 20px !important;
        margin-right: 8px !important;
      }
    }
  }
}

.StageCountdown {
  display: flex;
  align-items: center;
  gap: 10px;
  :global {
    .ant-statistic-content-value {
      color: white;
    }
  }
}

.Cost {
  text-align: right;
  justify-content: flex-end;
  color: black;
  gap: 5px;
  :first-child {
    color: black;
    opacity: 0.5;
  }
}

.StageTag {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: black;
  border-radius: var.$border-radius 0 calc(var.$border-radius * 3) 0;
  min-width: 250px;
  color: #ffcd1d;
  font-weight: 900;
  font-size: 28px;
  padding: 15px 25px;

  span {
    font-weight: 700;
    font-size: 16px;
    text-align: right;
    color: #ffffff;
    display: inline-block;
    margin-left: 10px;
  }
}

.StageServings {
  color: #9692a9;
  font-weight: 700;
  font-size: 16px;
}

.StageServingsActive {
  color: #ffcd1d;
}

.Attention {
  text-align: left;
  display: grid;
  gap: 10px;
  background: var.$color-card-background;
  border-radius: var.$border-radius;
  padding: 25px;
  color: black;
  display: grid;
  gap: 10px;
  width: 100%;
}

.Attention2 {
  text-align: left;
  display: grid;
  gap: 10px;
  background: rgba($color: #16122d, $alpha: 0.2);
  border-radius: var.$border-radius;
  padding: 18px 22px;
  color: #9644b4;
  display: grid;
  gap: 10px;
  margin-top: 32px;
}

.AttentionDesc {
  display: grid;
  gap: 10px;
}

.AttentionTitle {
  @include flex;
  justify-content: flex-start;
  gap: 15px;
  font-weight: 700;
  font-size: 16px;
  color: #d64389;
  img {
    width: 20px;
  }
}

.ThemisGavel {
  @include flex;

  align-items: stretch;
  max-width: 1000px;
  margin: 50px auto;
  text-align: left;
  gap: 20px;
  video {
    width: 40%;
    border-radius: var.$border-radius;
  }
}

.ThemisGavelTitle {
  text-align: left !important;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.ThemisGavelInfoWarp {
  width: 60%;
  color: #fff;

  h3 {
    font-size: 25px;
  }

  p {
    font-size: 14px;
  }
}

.BlueText {
  color: #4775fa;
}

.BlueBg {
  background: rgba($color: #3e44cf, $alpha: 0.2);
}

.Invite {
  background: linear-gradient(102.16deg, #05c8c8 0%, #59d89d 100%);
  border-radius: var.$border-radius;
  max-width: 1000px;
  margin: 0 auto;
  // margin-bottom: 50px;
  min-height: 200px;
  position: relative;
  padding: 30px 0 30px 30px;
  color: #fff;
}

.InviteTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 700;
  font-size: 48px;
  line-height: 140%;
  gap: 15px;
  @media (max-width: var.$mobile-break-point) {
    font-size: 28px;
  }
}

.InviteTextBg {
  position: absolute;
  width: 58%;
  height: auto;
  left: 36px;
  top: 22px;
}

.InviteLeft {
  text-align: left;
}

.InviteContentWarp {
  @include flex;
  align-items: center;
  position: relative;
  justify-content: flex-start;
  z-index: 1;
}

.InviteLogo {
  height: 40px;
}

.InviteAwesome {
  width: 100px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.buyButton {
  background: white;
  height: 45px;
  padding: 0 20px;
  margin-right: 20px;
  color: var.$color-primary;
}

.buyButtonText {
  font-size: 14px;
}

.ProtocolText {
  background: linear-gradient(277.23deg, #05c8c8 0%, #3e44cf 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-left: 10px;
}

.ProgressBarWarp {
  max-width: 1000px;
  margin: 0 auto;
  overflow: hidden;
}

.ProgressBar {
  max-width: 100%;
  height: 10px;
  margin: 0 auto;
  background: rgba($color: #2a2e52, $alpha: 0.5);
  border-radius: var.$border-radius;
}

.Begins {
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  margin: 10px 0 10px;
}

.ProgressBarActive {
  width: 0;
  background: linear-gradient(90deg, #bb6ce6 0%, #ffcd1d 100%);
  border-radius: var.$border-radius;
  height: 10px;
  transition: all 0.5s linear;
}

.Rrewards {
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 302px;
  padding: 0 10px;
  height: 47px;
  background: linear-gradient(102.16deg, #0fabab 0%, #59d89d 100%);

  border-radius: 50px;
  font-size: 14px;
  // flex: 0 0 302px;
  white-space: nowrap;

  img {
    margin-right: 5px;
    width: 20px;
  }
}
.logoWrap {
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    height: 30px;
    width: auto;
  }
  h2 {
    color: white;
    font-size: 24px;
    font-weight: 700;
  }
}

.Protocol {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;

  :global {
    .ant-checkbox-wrapper {
      color: rgba(black, 0.5) !important;

      margin: 3px 10px;
    }
  }
}

.loadingPlaceholder {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.currencySelector {
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 50px;
  background: var.$color-card-background;
  .optionItem {
    font-size: 12px;
    line-height: 12px;
    color: var.$color-primary;
    font-weight: 700;
    display: flex;
    align-items: center;
    padding: 5px 8px;
    border-radius: 50px;
    cursor: pointer;
    background-color: transparent;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    img {
      width: 18px;
      margin-right: 5px;
    }
    &.active {
      background-color: black;
    }
  }
}
.successModal {
  padding: 20px;
  background: var.$color-card-background;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.getTokenLink {
  color: var.$color-primary;
  align-self: flex-end;
  font-size: 16px;
  font-weight: 700;
}

.stakeHeader {
  width: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  padding-top: 70px;
}

@media (max-width: var.$mobile-break-point) {
  .Attention {
    margin: 20px auto;
  }
  .Attention2 {
    margin-top: 20px;
  }
  .Coming {
    height: 360px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .Participate {
    // padding: 0 15px 1px;
    min-height: 60vh;
  }
  .ParticipateBuy {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  .ParticipateBuyWarp {
    flex-direction: column;
    align-items: center;
  }

  .ParticipateBuyForm {
    max-width: 100%;
    width: 100%;
    padding: 10px;
  }

  .PaddingWarp {
    padding-left: 20px;
  }

  .ParticipateBuyImageWarp {
    width: 100%;
  }

  .StageTag {
    font-size: 14px;
    height: 55px;
    span {
      display: block;
      font-size: 12px;
    }
  }

  .ThemisGavel {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;
    margin-top: 30px;
    video {
      width: 80%;
    }
  }

  .ThemisGavelInfoWarp {
    width: 100%;
  }

  .InviteContentWarp {
    flex-wrap: wrap;
  }

  .InviteTitle {
    flex-wrap: wrap;
  }

  .InviteLeft,
  .InviteRight {
    width: 100%;
  }

  .InviteRight {
    justify-content: flex-start;
  }

  .InviteLeft {
    position: relative;
    z-index: 1;
  }

  .InviteAwesome {
    top: 30%;
  }

  .InviteTitleText {
    width: 100%;
  }

  .InviteDesc {
    width: 250px;
    margin: 10px 0;
  }

  .ThemisGavelTitle {
    align-items: center;
    h3 {
      font-size: 24px;
      text-align: center;
    }
  }
  .currencySelector {
    .optionItem {
      font-weight: 600;
      padding: 3px 5px;
      img {
        width: 16px;
        margin-right: 3px;
      }
    }
  }
}
.treasuryContainer {
  padding-top: 30px;
  padding-bottom: 30px;
}
