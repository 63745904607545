@use '../../../variables' as var;

.Airdrop {
  position: relative;
  width: 100%;
  background-color: var.$color-primary-background;
}
.banner {
  background-color: #212222;
  color: white;
  height: 160px;
  width: 100%;
  .back {
    cursor: pointer;
  }
}
.bannerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  gap: 20px;
}
.bannerLeftWrap {
  display: flex;
  align-items: center;
  gap: 20px;
}
.colorPrimary {
  color: var.$color-primary;
}
.tabStyles {
  background-color: #212222;
  border: 1px solid black;
  border-radius: 20px;
  height: 30px;
  line-height: 26px;
}
.tabItemStyles {
  // width: 100px;
  width: fit-content;
  font-size: 16px;
  padding: 0 10px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 60vh;
  justify-content: center;
}
.cardWrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.airdropCardStyle {
  width: 100%;
  background-color: white;
  border-radius: 20px !important;
  margin-top: 20px !important;
  height: 100%;
  flex: 1;
}

.airdropCard {
  flex: 1;
  width: 100%;
  text-align: left;
  font-family: 'PP Mori';
  display: flex;
  flex-direction: column;

  .airdropHeader,
  .airdropAmount {
    font-weight: 600;
    font-size: 22px;
  }
  .airdropAmount,
  .airdropPrimary {
    color: var.$color-primary;
  }
  .airdropBold {
    font-weight: 600;
  }
  .airdropValue {
    text-align: right;
  }
  .airdropButton {
    width: 180px;
    color: black;
  }
  .airdropButtonGroup {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
}

.howToEarn {
  text-align: center;
  font-family: 'PP Mori',sans-serif;
  font-weight: 600;
  font-size: 25px;
  color: var.$color-primary;
  text-decoration: underline;
}

.howToEarnDesc {
  font-family: 'PP Mori',sans-serif;
  padding-left: 10px;
  text-align: left;
}
.claimButton {
  width: 180px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}
.countdown {
  :global {
    .ant-statistic-content {
      display: flex;
      align-items: center;
    }
    .ant-statistic-content-value {
      color: white;
      font-size: 16px;
    }
  }
}
@media (max-width: var.$mobile-break-point) {
  .bannerContainer {
    flex-direction: column;
  }

  .bannerLeftWrap {
    width: 100%;
    justify-content: space-between;
  }

  .airdropCardStyle {
    // height: 470px;
  }

  .airdropCard {
    align-items: center;
  }
  .cardWrap{
    flex-direction: column;
  }

  .container {
    justify-content: center;
  }

  .howToEarn {
    text-align: center;
  }
}
