@use '../../variables' as var;

.GasShare {
  position: relative;
  min-height: 62vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.poolCard {
  width: 100%;
  max-width: 400px;
  background: #ffffff;
  border: 1px solid #dedede;
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.cardHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .tokenLogo {
    height: 35px;
  }
  .title {
    font-weight: 900;
    text-align: left;
    h2 {
      font-weight: 900;
      font-size: 22px;
      margin-bottom: 5px;
    }
    a {
      font-size: 16px;
      color: var.$color-primary;
      img {
        margin-right: 5px;
        width: 18px;
      }
    }
  }
}

hr {
  margin: 0;
  border: none;
  border-top: 1px solid #dedede;
}
.infoWrap,
.actionWrap {
  width: 100%;
  display: flex;
  gap: 10px;
}
.infoWrap {
  flex-direction: column;
}

.button {
  flex: 1 1 0;
}

@media (max-width: var.$mobile-break-point) {
  .LinearUnlock {
    min-height: 60vh;
  }
}
