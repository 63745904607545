.KYCModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background-color: white;
  padding: 15px 10px;
  text-align: center;
  h2 {
    color: black;
    font-weight: 900;
    font-size: 22px;
  }
  p {
    color: rgba(black, 0.8);
    font-size: 16px;
    font-weight: 500;
  }
}
