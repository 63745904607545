@use '../../variables' as var;

.Blocked {
  width: 100vw;
  height: 100vh;
  background-color: var.$color-primary;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    color: white;
    font-size: 38px;
    font-weight: 900;
    text-align: center;
    margin-bottom: 25px;
  }
  h3 {
    color: white;
    font-size: 28px;
    font-weight: 900;
    text-align: center;
  }
}

@media (max-width: var.$mobile-break-point) {
  .Blocked {
    h2 {
      font-size: 28px;
    }
    h3 {
      font-size: 18px;
    }
  }
}
