@use '../../../variables' as var;
.TitleCombo {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    font-size: 40px;
    line-height: 110%;
    color: black;
  }
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: black;
  }
  &.small {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 700;
      font-size: 25px;
    }
    p {
      font-weight: 400;
      font-size: 14px;
    }
  }
  &.white {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: white;
    }
  }
}
@media (max-width: var.$mobile-break-point) {
  .TitleCombo,
  .TitleCombo.small {
    gap: 15px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 24px;
    }
    p {
      font-size: 14px;
      text-align: justify;
    }
  }
}
