@use '../../variables' as var;

:global(.ant-alert) {
  background: #8d8d8d !important;
  padding: unset !important;
}
:global(.anticon-close) {
  color: white !important;
  opacity: 0.7 !important;
  font-size: 15px;
  margin: 20px 20px 0 0;
}
.alert {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  overflow: hidden;
  .addressTitle,
  .address {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
    color: var.$color-primary;
  }
  .addressTitle {
    font-weight: 700;
  }
  .address {
    color: black;
  }
}
.textWrap {
  display: flex;
  align-items: center;
  background: white;
  border-radius: var.$border-radius;
  padding: 15px;
  gap: 25px;
  margin: 15px 0;
}
.titleWrap {
  display: flex;
  // flex-direction: column;
  // align-items: flex-start;
  gap: 15px;
}

.desc {
  font-size: 14px;
  line-height: 20px;
  color: rgba(#000, 0.5);
  max-width: 500px;
  font-weight: 600;
}
.icon {
  height: 80px;
  align-self: flex-end;
}
@media (max-width: 900px) {
  .icon {
    display: none;
  }
  .textWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    background-color: transparent;
    padding: 15px 0 15px 15px;
  }
  .titleWrap {
    flex-direction: column;
    align-items: flex-start;
  }
  // .addressTitle,
  // .address {
  //   color: black;
  // }

  // .desc {
  //   color: rgba(#000, 0.5);
  // }
  .address {
    // overflow: scroll;
    max-width: 325px;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    color: white !important;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
