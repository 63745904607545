@use '../../../variables' as var;


.Tab {
  margin: auto;

  width: fit-content;
  height: 35px;
  background-color: white;
  border-radius: 20px;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 35px;

  color: var.$color-primary;

  display: flex;
  gap: 5px;
}

.active{
  border-radius: 20px;
  background-color: var.$color-primary;
  color: white;
}

.tabItem {
  cursor: pointer;
  width: 120px;
}

@media (max-width: var.$mobile-break-point) {
  .Tab {
    font-size: 16px;
  }
  .tabItem {
    cursor: pointer;
    width: 100px;
  }
}