@use '../../../variables' as var;

.CustomButton {
  position: relative;
  min-width: 60px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 12px;
  height: 45px;
  padding: 0 25px;
  background-color: var.$color-primary;
  border-radius: 100px;
  color: white;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  z-index: 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  &:hover {
    color: white;
  }
}

// .gray {
//   background: #231f4f;
// }

.secondary {
  background: transparent;
  color: var.$color-primary;
  border: 2px solid var.$color-primary;
  &:hover {
    color: var.$color-primary;
  }
}
.rounded {
  border-radius: 100px;
}
.disabled,
.loading {
  background-color: #cdcdcd;
  color: rgba(#000, 0.5);
  pointer-events: none;
}

@media (max-width: var.$mobile-break-point) {
  .CustomButton {
    font-size: 14px;
  }
}
