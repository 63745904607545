.InfoItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  .title {
    color: rgba(#1d1b27, 0.5);
    font-weight: 400;
  }
  .value {
    font-weight: 700;
  }
}
