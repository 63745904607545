@use '../../variables' as var;

.RewardsPool {
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: white;
}
.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
}

@media (max-width: var.$mobile-break-point) {
  .mainContainer {
    min-height: 60vh;
  }
}
