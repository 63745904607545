@use '../../variables' as var;

.LearnMore {
  // padding: 25vw 0 0 0;
  // padding-top: 100px;
  background-color: rgba($color: #dbe8e2, $alpha: 0.5);
}

.EcosystemTitleSec {
  margin-top: 25px;
  h2 {
    font-size: 28px;
  }
}
.Card {
  border-radius: var.$border-radius;
  width: fit-content;
  margin: 0 auto;
  padding: 50px;
}

.CorePhilosophWarp {
  display: flex;
  justify-content: space-between;
  max-width: 1149px;
  margin: 0 auto;
  margin-top: 60px;
}

.CorePhilosophItem {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 30px;
  gap: 40px;
  max-width: 700px;
  background: white;
  border-radius: var.$border-radius;
  margin: 0 20px;
  img {
    width: 48px;
  }
}

.CorePhilosophItemContentWarp {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
}

.CorePhilosophItemTitle {
  font-weight: 700;
  font-size: 18px;
  color: black;
  margin: 15px 0;
}

.CorePhilosophItemContent {
  color: rgba(#060606, 0.5);
}

.EcosystemMap {
  padding: 25px 0;
  background-color: white;
  img {
    width: 100%;
    max-width: 1140px;
  }
}

.Infrastructure {
  background-color: rgba($color: #dbe8e2, $alpha: 0.5);
  padding: 80px 10px;
  padding-top: 1px;
}

.InfrastructureDesc {
  color: #fff;
  text-align: left;
  max-width: 1150px;
  margin: 0 auto;
}

.InfrastructureWarp {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1150px;
  margin: 0 auto;
  margin-top: 50px;
  gap: 35px;
}

.InfrastructureItem {
  color: #fff;
  width: 100%;
  background-color: white;
  border-radius: var.$border-radius;
  height: 100%;
  padding: 15px;
  img {
    width: 80%;
  }
}

.InfrastructureItemTitle {
  margin-top: 20px;
  h2 {
    font-size: 20px;
    min-height: 80px;
  }
}

.InfrastructureItemContent {
  width: 90%;
  font-size: 14px;
  color: rgba(#060606, 0.5);
  text-align: justify;
}

@media (max-width: var.$mobile-break-point) {
  .CorePhilosophWarp {
    flex-wrap: wrap;
  }

  .CorePhilosophItem {
    width: 100%;
    margin-bottom: 20px;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
  .EcosystemTitleSec {
    h2 {
      font-size: 18px;
    }
  }
  .InfrastructureWarp {
    margin-top: 30px;
    width: 95%;
  }
  .Card {
    // max-width: 1400px;
    margin: 0 auto;
    padding: 30px 10px;
  }

  // .InfrastructureWarp {
  //   flex-wrap: wrap;
  // }

  // .InfrastructureItem {
  //   width: 100%;
  // }
}
