@use '../../variables' as var;
$border: 2px;

.WalletMenu {
  position: absolute;
  width: calc(100vw - 2 * var.$header-h-padding);
  max-width: 450px;
  max-height: 70vh;
  height: fit-content;
  padding: 35px;
  bottom: 100%;
  right: var.$header-h-padding;
  background-color: var.$color-header-background;
  opacity: 0.7;
  border-radius: var.$border-radius;
  transition-property: opacity, transform;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  z-index: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  &.visible {
    transform: translateY(calc(100% + 10vh));
    opacity: 1;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}
.disconnectMenu {
  background-color: var.$color-header-background;
  width: fit-content;
  gap: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 12px;
    color: #ffffff;
    margin: 0;
  }
  .disconnectButton {
    padding: 5px;
    margin-top: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 12px;
    color: var.$color-red;
    cursor: pointer;
  }
}
.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  margin-bottom: 20px;
  color: white;
}
.MenuItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px 0;
  background: rgba(0, 0, 0, 0.2);
  background-clip: padding-box; /* !importanté */
  border: solid $border transparent; /* !importanté */
  position: relative;
  box-sizing: border-box;
  border-radius: var.$border-radius;
  cursor: pointer;
  img {
    width: 48px;
  }
  h2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    white-space: nowrap;
    color: white;
  }
  &:hover {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -$border;
      border-radius: inherit;
      border: solid $border var.$color-primary;

      // background: var.$color-title-gradient;
    }
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.4);
  display: none;
  &.visible {
    display: unset;
  }
}

@media (max-width: var.$mobile-break-point) {
  .WalletMenu {
    position: fixed;
    bottom: auto;
    top: 100%;
    right: 0;
    width: 100%;
    max-width: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    &.visible {
      transform: translateY(-100%);
    }
  }
}
