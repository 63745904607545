@use '../../../variables' as var;

:global {
  .ant-input {
    border-radius: var.$border-radius !important;
    background-color: white !important;
    height: 40px;
    color: black !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    padding: 0 20px !important;
    border: 1px solid var.$color-primary !important;
  }
}
