@use '../../variables' as var;
.About {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.banner {
  height: 100vh;
  width: 100%;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center bottom;
  }
}
.bannerTextWrap {
  position: absolute;
  top: 2vh;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;
  max-width: 1000px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.bannerButtonWrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  div {
    width: 250px;
  }
}
.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  // padding-bottom: 0;
  max-width: 1000px;
}
.mainContent {
  width: 100%;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  &::after {
    content: 'placeholder';
    visibility: hidden;
  }
}

.getNodeWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  .buttonWrap {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
    .getNftButton {
      width: 180px;
    }
  }
}
.getNodeCombo {
  max-width: 900px;
}
.statsWrap {
  width: 100%;
}
.statsItem {
  width: 100%;
  border: 2px solid #545454;
  background-color: rgba($color: #000000, $alpha: 0.3);
  border-radius: var.$border-radius;
  display: flex;
  overflow: hidden;
}
.statsIconWrap {
  border-right: 2px solid #545454;
  background: #08021f;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  img {
    width: 30px;
  }
}
.statsTextWrap {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
  h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 18px */
    text-align: center;
  }
  p {
    font-weight: 700;
    font-size: 22px;
    line-height: 100%;
    text-align: center;
    color: #e7e7e7;
  }
}
.nftIntro {
  // width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.nftIntroItem {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 50px;
  video {
    border-radius: var.$border-radius;
    width: 40%;
  }
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    /* identical to box height, or 24px */
    color: #e7e7e7;
  }
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
  }
  &.second {
    justify-content: flex-end;
  }
}
.nftIntroAction {
  width: 40%;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  gap: 25px;

  &.second {
    text-align: right;
    align-items: flex-end;
  }
  h2,
  p {
    color: black;
  }
}
.nftIntroWrap {
  width: 100%;
  background-color: rgba($color: #dbe8e2, $alpha: 0.5);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.pointsSystemWrap {
  width: 100%;
  // height: 70vh;
  // background-position: center top;
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pointsSystemContainer {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}
.saleWrap {
  margin-bottom: 50px;
  background: linear-gradient(102.16deg, #05c8c8 0%, #59d89d 100%);
  border-radius: var.$border-radius;
  padding: 50px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  overflow: hidden;
}
.saleTextWrap {
  display: flex;
  align-items: center;
  gap: 15px;
  .logoWrap {
    display: flex;
    align-items: center;
    gap: 10px;
    img {
      height: 35px;
      width: auto;
    }
  }

  .vbar {
    border: none;
    border-right: 1px solid rgba(white, 0.8);
    height: 40px;
  }
  h2 {
    color: white;
    font-size: 28px;
    font-weight: 700;
  }
}
.saleWatermark {
  position: absolute;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.buyButton {
  background: white;
  color: var.$color-primary;
  height: 45px;
  padding: 0 20px;
}
.whitepaperButton {
  color: var.$color-primary;
}

@media (max-width: var.$mobile-break-point) {
  .mainContainer {
    gap: 50px;
  }
  .pointsSystemWrap {
    height: 50vh;
  }
  .bannerTextWrap {
    top: 2vh;
  }
  .bannerButtonWrap {
    flex-direction: column;
  }
  .nftIntroItem {
    gap: 25px;
    flex-direction: column;
    .nftIntroAction,
    video {
      width: 100%;
      max-width: 500px;
    }
    h2 {
      font-size: 16px;
    }
    p {
      font-size: 14px;
    }
    &.second {
      flex-direction: column-reverse;
    }
  }
  .nftIntroAction,
  .nftIntroAction.second {
    align-items: center;
    text-align: center;
  }
  .saleWrap {
    flex-direction: column;
    padding: 30px 20px;
  }
  .saleTextWrap {
    flex-direction: column;
    width: 100%;
    gap: 25px;
    .vbar {
      border: none;
      border-bottom: 1px solid rgba(white, 0.8);
      width: 100%;
      height: auto;
    }
    .saleText {
      width: 90%;
      height: auto;
      max-width: 300px;
    }
  }
  .saleWatermark {
    height: auto;
    width: 90%;
  }
  .getNodeWrap {
    .buttonWrap {
      gap: 10px;
      .getNftButton {
        width: 160px;
      }
    }
  }
}
