@use '../../variables' as var;
.Swap {
  position: relative;
  width: 100%;
  background-color: white;
}
.mainContent {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.swapWrap {
  background-color: var.$color-card-background;
  border-radius: var.$border-radius;
  padding: 15px;
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.swapTitle {
  font-size: 24px;
}
.actionWrap {
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  > img {
    width: 35px;
    margin: 15px 0;
  }
}
.balance {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  font-size: 14px;
  margin-bottom: 5px;
  :first-child {
    font-weight: 700;
  }
}
.button {
  margin-top: 15px;
  width: 100%;
}
.input {
  position: relative;
  width: 100%;
  .suffix {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: var.$color-primary;
    color: white;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    padding: 5px 10px;
    border-radius: 50px;
    margin-right: 5px;
  }
}
.arrow {
  filter: invert(78%) sepia(61%) saturate(388%) hue-rotate(90deg) brightness(89%) contrast(89%);
}
