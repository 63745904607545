@use '../../../variables' as var;
.RewardsPoolAction {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  > img {
    width: 35px;
  }
}
.title {
  font-weight: 700;
  font-size: 24px;
  /* identical to box height, or 67% */
  text-align: center;
  text-transform: uppercase;
  color: black;
}
.actionWrap {
  max-width: 400px;
  min-height: 200px;
  width: 100%;
  border: 1px solid #dedede;
  border-radius: var.$border-radius;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  h2,
  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height, or 100% */

    text-align: center;
    text-transform: uppercase;

    color: black;
  }
  p {
    color: black;
  }
}
.claimButton {
  width: 100%;
}
