@use '../../../../variables' as var;
.RewardsPlan {
  width: 100%;
  // background: var.$color-card-background;
  // border-radius: var.$border-radius;
  // padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
}
.title {
  text-align: left;
  font-size: 22px;
  font-weight: 600;
  opacity: 0.85;
  line-height: 1.5715;
}
.planWrap {
  width: 100%;
  display: flex;
  gap: 15px;
}
.planCard {
  flex: 1 1 0;
  width: 100%;
  background: white;
  border-radius: 20px;
  border: 1px solid #f0f0f0;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  overflow: hidden;
  &:first-child {
    justify-content: space-around;
  }
}
.infoWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  h2 {
    font-size: 16px;
  }
  p {
    font-size: 52px;
    font-weight: 700;
    color: black;
  }
}
.infoWrapActivate {
  p {
    color: var.$color-primary;
    font-size: 36px;
  }
}
.actionWrap {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 15px;
}
.linkButton {
  width: 150px;
}
.infoItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dataWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  text-align: left;
  .dataTitle {
    font-size: 16px;
    line-height: 16px;
    img {
      width: 15px;
    }
  }
  h3 {
    margin-top: 5px;
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
  }
  h4 {
    margin: 0;
    font-size: 14px;
    color: #1d1b27;
    opacity: 0.5;
  }
}

// :global(.ant-modal-content) {
//   background: #27224f;
// }
.claimModalContent {
  padding: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.claimModalInfoItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 15px;
  }
  h2 {
    font-size: 16px;
  }
  p {
    font-size: 16px;
    font-weight: 700;
    span {
      opacity: 0.5;
    }
  }
}
.desc {
  font-size: 16px;
  line-height: 120%;
  color: black;
  opacity: 0.5;
  margin: 20px 0;
}
.descWrap {
  width: 100%;
}
.descItem {
  width: 100%;
  background: #7a8290;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 25px;
  .percentage {
    font-weight: 700;
    font-size: 36px;
    line-height: 100%;
    color: var.$color-primary;
  }
  .descCountWrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      font-size: 16px;
      line-height: 18px;
      color: white;
      text-align: left;
    }
    .count {
      background: var.$color-primary;
      border-radius: 10px;
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-weight: 700;
      font-size: 24px;
      color: #ffffff;
    }
  }
}
.countdown {
  :global {
    .ant-statistic-content {
      display: flex;
      align-items: center;
    }
    .ant-statistic-content-value {
      color: white;
      font-size: 16px;
    }
  }
}
@media (max-width: var.$mobile-break-point) {
  .planWrap {
    flex-direction: column;
  }
  .planCard {
    width: 100%;
  }
  .descItem{
    padding: 15px;
  }
}
