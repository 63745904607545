@use '../../variables' as var;
$nav-item-color: rgba(255, 255, 255, 0.5);

.Header {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var.$header-v-padding var.$header-h-padding;
  z-index: 999;
  background-color: var.$color-header-background;
}
.menuButton {
  &:hover {
    cursor: pointer;
  }
}
.menu {
  background: var.$color-header-background !important;
  border-radius: var.$border-radius !important;
  padding: 5px !important;
  :global(.ant-dropdown-menu-item) {
    color: white !important;
    &:hover {
      color: var.$color-primary !important;
      background: transparent !important;
      border-radius: var.$border-radius !important;
    }
  }
  :global(.ant-dropdown-menu-item-active) {
    background: transparent !important;
    border-radius: var.$border-radius !important;
  }
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.4);
  display: none;
  &.visible {
    display: unset;
  }
}
.logo {
  display: flex;
  align-items: center;
  img {
    height: 30px;
    margin-right: 15px;
  }
  h2 {
    color: white;
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
  }
}
.menuWrap {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  gap: 15px;
}
.navWrap {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  gap: 10px;
  .navItem {
    padding: 5px;
    font-weight: 700;
    font-size: 16px;
    color: $nav-item-color;
    text-decoration: none;
    cursor: pointer;
    &.active,
    &:hover {
      color: var.$color-primary;
    }
  }
}
.walletButton {
  min-width: 120px;
}

.langButton {
  cursor: pointer;
  height: 25px;
}

@media (max-width: var.$mobile-break-point) {
  .logo {
    img {
      height: 30px;
    }
    h2 {
      font-size: 20px;
      line-height: 20px;
    }
  }
  .menuWrap {
    width: 100%;
    position: absolute;
    bottom: 1px;
    left: 0;
    transform: translateY(100%);
    background-color: #212222;
    flex-direction: column;
    align-items: flex-start;
    padding: calc(var.$header-v-padding * 2) var.$header-h-padding;
  }
  .navWrap {
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 5px;
    .navItem {
      padding: 5px 10px;
      width: 100%;
      border-radius: var.$border-radius;
      text-align: left;
      color: white;
    }
  }
  .walletButton {
    height: 40px;
  }
  .langButton {
    margin: 20px;
    margin-top: 0;
  }
}

.navMenu {
  background: var.$color-header-background !important;
  border-radius: var.$border-radius !important;
  padding: 10px !important;
  :global(.ant-dropdown-menu-item) {
    color: $nav-item-color;
    padding: 10px 5px;
    font-weight: 700;
    font-size: 16px;
    &:hover {
      background: transparent !important;
      color: var.$color-primary;
    }
  }
  :global(.ant-dropdown-menu-item-active) {
    background: transparent !important;
  }
}
