.comingSoonWrap {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
      90deg,
      rgba(0, 195, 255, 0.05) 0%,
      rgba(88, 98, 227, 0.05) 55.29%,
      rgba(129, 38, 217, 0.05) 100%
    ),
    rgba(244, 247, 250, 0.5);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  display: grid;
  place-content: center;
}

.comingSoonNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 40px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  @media screen and (max-width: 1023px) {
    padding: 16px 20px;
    justify-content: center;
  }
}
.comingSoonNavLinks {
  position: relative;
  padding: 6px 14px;
  @media screen and (max-width: 1023px) {
    display: none;
  }
}
.navLink {
  font-size: 18px;
  padding: 0 20px;
  color: #333333;
  :hover {
    color: black !important;
  }
}
.halfBracketTopLeft {
  position: absolute;
  top: 0;
  left: 0;
}
.halfBracketBottomLeft {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: rotate(-90deg);
}
.halfBracketTopRight {
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(90deg);
}
.halfBracketBottomRight {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
}
.comimgSoonLogo {
  img {
    width: 100%;
    height: 28px;
  }
}
.comingSoonFooter {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 16px 0;
}

.halfBracketWidth {
  width: 10px;
  height: 1.5px;
  background-color: #333333;
}
.halfBracketHeight {
  height: 10px;
  width: 1.5px;
  background-color: #333333;
}

.comingSoonHeading {
  font-size: 72px;
  font-weight: 700;
  @media screen and (max-width: 1023px) {
    font-size: 48px;
  }
}
.comingSoonParagraph {
  max-width: 768px;
  font-size: 16px;
}
.notifyMeButton {
  margin-top: 24px;
  padding: 12px 24px;
  font-size: 16px;
  background: #1a1a1a;
  color: #ffffff;
  border: 0;
  cursor: pointer;
  font-weight: 500;
  @media screen and (max-width: 1023px) {
    margin-top: 16px;
    padding: 12px 16px;
  }
}
a:hover {
  color: #1a1a1a;
}
