@use '../../variables' as var;

.Claim {
  position: relative;
  width: 100%;
}
.titleWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0;
  gap: 25px;
  h2 {
    font-size: 24px;
    font-weight: 900;
  }
}
.navWrap {
  background-color: #000000;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  padding: 2px;
  .navItem {
    font-size: 16px;
    line-height: 14px;
    font-weight: 900;
    padding: 8px 12px;
    border-radius: 9999px;
    color: var.$color-primary;
    &.active {
      background-color: var.$color-primary;
      color: white;
    }
  }
}

@media (max-width: var.$mobile-break-point) {
  .titleWrap {
    flex-direction: column;
    align-items: center;
  }
  .navWrap {
    .navItem {
      font-size: 14px;
      line-height: 12px;
      padding: 4px 8px;
      padding-bottom: 5px;
    }
  }
}
