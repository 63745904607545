@use '../../variables' as var;

.square {
  position: relative;
  width: 100%;
}

.square:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.card {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 30px;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    transform: scale(1.01);
    .cardContent {
      background-color: var.$color-primary;
      color: #fff;
    }
  }
}

.cardOverlay {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000, $alpha: 0.45);
  border-radius: 30px;
}

.cardLogo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

.cardContent {
  position: absolute;
  width: 100%;
  bottom: 0;
  font-family: 'PP Mori';
  font-size: 24px;
  line-height: 40px;
  color: #fff;
  background-color: rgba($color: #000, $alpha: 0.45);
}
