@use '../../../variables' as var;
@mixin flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.NumberInput {
  @include flex;
  user-select: none;
}

.NumberButton {
  @include flex;
  justify-content: center;
  width: 72px;
  height: 40px;
  background: var.$color-card-background;

  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }
}

.NumberButtonLeft {
  border-radius: var.$border-radius 0px 0px var.$border-radius;
}

.NumberButtonRight {
  border-radius: 0px var.$border-radius var.$border-radius 0px;
}

.NumberInputValue {
  width: 100%;
  height: 40px;
  border: none !important;
  background: #d7d7d7;
  text-align: center;
  color: black;
  font-weight: 900;
  font-size: 16px;
  &:focus {
    outline: none;
  }
}
