.tooltip {
  // display: flex;
  // gap: 10px;
  // font-weight: 400;
  // font-size: 16px;
  // line-height: 140%;
  // /* identical to box height, or 28px */

  // text-align: center;

  // p {
  //   color: black;
  // }

  // img {
  //   width: 15px;
  // }
}
.tip {
  padding: 5px;
  color: rgba(black, 0.5);
  p {
    color: rgba(black, 0.5);
  }
  // div {
  //   padding: 10px;
  //   h2 {
  //     font-weight: 700;
  //     font-size: 18px;
  //     line-height: 16px;
  //     /* identical to box height, or 67% */

  //     text-transform: uppercase;

  //     color: black;
  //   }
  //   p {
  //     margin-top: 15px;
  //     color: black;
  //     font-size: 16px;
  //     line-height: 20px;
  //     opacity: 0.5;
  //   }
  // }
}
