@use '../../variables' as var;
.Footer {
  background-color: #131313;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: #ffff;
  padding: 56px 100px;
  text-align: left;
  gap: 30px;
  li {
    list-style-type: none;
    margin: 3px 0;
  }

  ul {
    padding: 0;
  }

  a {
    font-size: 14px;
    font-weight: 500;
    color: white;
    transition: all 0.5s ease;

    &:hover {
      color: var.$color-primary;
    }
  }
}

.FooterLogo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 55px;
    margin-right: 20px;
  }
  h2 {
    font-size: 32px;
    line-height: 120%;
    font-weight: 700;
    color: white;
  }
}

.FooterDesc {
  max-width: 300px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  line-height: 120%;
  font-weight: 500;
}

.FooterColumn {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 220px;
  // gap: 50px;
}

.FlexColumn {
  flex-direction: column;
}

.FooterDesc {
  text-align: left;
  margin-bottom: 10px;
}
.LinkWrap {
  width: 100%;
  flex: 1;
}
.LinkColumn {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: rgba(white, 0.5);
  }
}
.CopyRight {
  color: rgba(#fafafa, 0.7);
  font-size: 14px;
  text-decoration: none;
  padding: 0 0 30px;
  background-color: #131313;
}

@media (max-width: 1100px) {
  .Footer {
    flex-wrap: wrap;
    padding: 20px 25px;

    .FooterColumn {
      width: 100%;
    }
  }
}
