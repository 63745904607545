@use '../../variables' as var;

.header {
  background-color: #010101;
  .headerContainer {
    padding: 25px 0;
  }
  .headerTitle {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    h2,
    p {
      color: white;
    }
    h2 {
      font-weight: 900;
      font-size: 28px;
    }
  }
}

.Farm {
  position: relative;
  width: 100%;
  .titleWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0;
    gap: 25px;
    h2 {
      font-size: 24px;
      font-weight: 900;
    }
  }
  .navWrap {
    background-color: #000000;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    padding: 2px;
    .navItem {
      font-size: 16px;
      line-height: 14px;
      font-weight: 900;
      padding: 8px 12px;
      border-radius: 9999px;
      color: var.$color-primary;
      &.active {
        background-color: var.$color-primary;
        color: white;
      }
    }
  }
}

.TokenPool {
  position: relative;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.poolCard {
  width: 100%;
  max-width: 400px;
  background: #ffffff;
  border: 1px solid #dedede;
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.cardHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .tokenLogo {
    height: 35px;
  }
  .title {
    font-weight: 900;
    text-align: right;
    h2 {
      font-weight: 900;
      font-size: 22px;
      margin-bottom: 5px;
    }
    a {
      font-size: 16px;
      color: var.$color-primary;
      img {
        margin-right: 5px;
        width: 18px;
      }
    }
  }
}
hr {
  margin: 0;
  border: none;
  border-top: 1px solid #dedede;
}
.infoWrap,
.actionWrap {
  width: 100%;
  display: flex;
  gap: 10px;
}
.infoWrap {
  flex-direction: column;
}
.actionWrap {
}
.button {
  flex: 1 1 0;
}
.countdown {
  display: flex;
  align-items: center;
  gap: 10px;
}
