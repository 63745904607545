.App {
  text-align: center;
  background-color: white;
  position: relative;
  overflow-x: hidden;
}

h1,
h2,
h3,
p {
  margin: 0;
  color: black;
}
