@use '../../variables' as var;

.Rewards {
  position: relative;
  width: 100%;
  padding-bottom: 1px;
  background: white;
}
.banner {
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
}
.bannerContainer {
  position: relative;
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  img {
    width: 25%;
  }
}
.textWrap {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  text-align: left;
  flex: 1;
  h2 {
    font-weight: 900;
    font-size: 32px;
    line-height: 120%;
    text-transform: uppercase;
    color: #94ffcd;
  }
  h1 {
    font-weight: 900;
    font-size: 40px;
    line-height: 100%;
    /* identical to box height, or 72px */
    letter-spacing: -0.02em;
    color: #fffef9;
  }
}
.mainContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  min-height: 70vh;
}
.statsWrap {
  width: 100%;
}
.statsItem {
  img {
    width: 40px;
  }
}
.descWrap {
  width: 100%;
}
.descItem {
  width: 100%;
  background: var.$color-card-background-dark;
  border-radius: var.$border-radius;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  .percentage {
    font-weight: 700;
    font-size: 36px;
    line-height: 100%;
    color: var.$color-primary;
  }
  .descCountWrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;

    h2 {
      font-size: 16px;
      line-height: 18px;
      color: white;
    }
    .count {
      background: var.$color-primary;
      border-radius: var.$border-radius;
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-weight: 700;
      font-size: 24px;
      color: #ffffff;
    }
  }
}

@media (max-width: var.$mobile-break-point) {
  .mainContent {
    min-height: 60vh;
  }
  .bannerContainer {
    flex-direction: column;
    img {
      position: absolute;
      width: 80%;
      max-width: 300px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.5;
    }
  }
  .textWrap {
    z-index: 1;
    h2 {
      font-size: 24px;
    }
    h1 {
      font-size: 32px;
    }
  }
}
