@use '../../../variables' as var;
.CustomModal {
  :global(.ant-modal-content) {
    box-sizing: border-box;
    background-color: white;
    border-radius: var.$border-radius;
    padding: 0;
    overflow: hidden;
  }
  :global(.ant-modal-body) {
    padding: 0;
    color: black;
  }
}
.titleWrap {
  width: 100%;
  display: flex;
  // margin-bottom: 24px;
  padding: 20px;
  img {
    width: 15px;
    cursor: pointer;
  }
  h2 {
    width: 100%;
    text-align: center;
    font-size: 20px;
    line-height: 100%;
    font-weight: 700;
    margin: 0;
    text-align: left;
    color: black;
  }
}
