@use '../../variables' as var;

.Airdrop {
  position: relative;
  width: 100%;
  background-color: white;
}
.banner {
  position: relative;
  background-color: var.$color-primary;
  height: 220px;
  width: 100%;
}
.silkBelt {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 220px;
  background-image: url('./bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  mix-blend-mode: hard-light;
  z-index: 1;
}
.airdropImage{
  position: absolute;
  top: 0;
  left: 50vw;
  width: 320px;
  height: 220px;
  background-image: url('./airdrop.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  z-index: 100;
}
.airdropText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 400px;
  z-index: 300;
  font-family: 'PP Mori';
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 100%;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
}
.airdropTextHorizon{
  align-self: flex-start;
  width: fit-content;
  // position: absolute;
  // left: 20vw;
}
.airdropTextAirdrop{
  align-self: flex-end;
  width: fit-content;
  // position: absolute;
  // top: 80px;
  // left: 40vw;
}
.container{
  background-color: var.$color-primary-background;
  padding-top: 32px;
  padding-bottom: 32px;
  min-height: 50vh;
}
.cardContainer {
  // display: flex;
  // justify-content: space-around;
  // flex-wrap: wrap;
  // min-height: 500px;
  // gap: 20px;
}
@media (max-width: var.$mobile-break-point) {
  .mainContent {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .airdropText {
    font-size: 60px;
  }
  .airdropTextHorizon{
    left: 10vw;
  }
  .airdropTextAirdrop{
    left: 10vw;
  }
}
