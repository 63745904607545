@font-face {
  font-family: 'PP Mori';
  src: url('./font/PPMori-Regular.ttf') format('opentype');
}
body {
  margin: 0;
  font-family: 'PP Mori', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
