.ProgressiveVideo {
  position: relative;
  height: 100vh;
  width: 100%;
  video,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center bottom;
    transition-duration: 0.5s;
    transition-delay: 300ms;
  }
  img {
    position: absolute;
    left: 0;
    top: 0;
  }
}
